import React from 'react';
import Row from '@components/cart/minimap/Row';

const Floor = ({ floor, selectedSeats }) => {
  const { x, y, seats } = floor;
  const rows = Array.from(Array(y).keys());
  const columns = Array.from(Array(x).keys());
  return (
    <div className="flex flex-col justify-center space-y-0.5 md:space-y-1 lg:space-y-0.5 xl:space-y-1.5 items-center w-full mb-3">
      {rows.map((y, index) => (
        <Row columns={columns} key={index} y={y} seats={seats} selectedSeats={selectedSeats} />
      ))}
    </div>
  );
};

export default Floor;
