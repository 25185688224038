import React from 'react';
import Seat from '@components/cart/minimap/Seat';

const Row = ({ columns, y, seats, selectedSeats }) => {
  const doesRowExist = seats.some((seat) => seat.y === y);
  const rowSeats = seats.filter((seat) => seat.y === y);

  return (
    <div className="flex items-center md:space-x-0.5 xl:space-x-1">
      <div className="text-[8px] md:text-xs w-2.5 md:w-3.5 text-center">
        {doesRowExist && rowSeats[0].row}
      </div>
      <div key={y} className="flex items-center md:space-x-0.5 xl:space-x-1">
        {columns.map((x, index) => (
          <Seat seats={seats} x={x} y={y} key={index} selectedSeats={selectedSeats} />
        ))}
      </div>
      <div className="w-2.5 md:w-3.5 text-xs md:text-sm text-center">
        {doesRowExist &&
          rowSeats[rowSeats.length - 1].row !== rowSeats[0].row &&
          rowSeats[rowSeats.length - 1].row}
      </div>
    </div>
  );
};

export default Row;
