import React from 'react';
import Seat from '@components/home/seatmap/Seat';

const Row = ({ columns, y, seats, selectedSeats, setSelectedSeats }) => {
  const selectRow = (e) => {
    const unbookedRowSeats = seats.filter((seat) => seat.y === y && !seat.booked);
    setSelectedSeats((prevSeats) => prevSeats.filter((seat) => seat.y !== y));
    if (e.target.checked) {
      setSelectedSeats((prevSeats) => [...prevSeats, ...unbookedRowSeats]);
    }
  };
  const filterSeatsByY = (seat) => seat.y === y;
  const doesRowExist = seats.some(filterSeatsByY);
  const rowSeats = seats.filter(filterSeatsByY);

  return (
    <div className="flex items-center space-x-0.5 lg:space-x-1 xl:space-x-1.5">
      <div className="text-[8px] md:text-sm lg:text-base xl:text-lg w-2 md:w-3.5 text-center">
        {doesRowExist && rowSeats[0].row}
      </div>
      {seats.some((seat) => seat.y === y && !seat.booked) ? (
        <input
          type="checkbox"
          checked={rowSeats
            .filter((seat) => !seat.booked)
            .every((seat) => selectedSeats.some((selectedSeat) => selectedSeat.id === seat.id))}
          className="w-2 h-2 sm:w-3 sm:h-3 md:h-3.5 md:h-3.5 lg:w-4 lg:h-4 xl:w-5 xl:h-5 2xl:w-6 2xl:h-6 text-blue-600 bg-gray-100 rounded-lg"
          onChange={(e) => selectRow(e)}
        />
      ) : (
        <div className="w-2.5 sm:w-3.5 md:w-5 lg:w-5.5 xl:w-6 2xl:w-7" />
      )}
      <div key={y} className="flex items-center sm:space-x-0.5 lg:space-x-1 xl:space-x-1.5">
        {columns.map((x, index) => (
          <Seat
            seats={seats}
            selectedSeats={selectedSeats}
            x={x}
            y={y}
            key={index}
            setSelectedSeats={setSelectedSeats}
          />
        ))}
        <div className="w-2 md:w-3.5 text-[8px] md:text-sm lg:text-base xl:text-xl text-center">
          {doesRowExist &&
            rowSeats[rowSeats.length - 1].row !== rowSeats[0].row &&
            rowSeats[rowSeats.length - 1].row}
        </div>
      </div>
    </div>
  );
};

export default Row;
