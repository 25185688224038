import React, { useState } from 'react';
import Input from '@core/inputs/Input';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import useFormErrors from '@hooks/useFormErrors';
import checkoutFormValidator from '@utils/validators/checkoutFormValidator';
import SeatsService from '@services/SeatsService';
import { showError } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';

const CheckoutForm = ({ seats }) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const { validateErrors, getError, clearErrors } = useFormErrors();
  const dispatch = useDispatch();

  const payWithPok = () => {
    const client = { name, surname, email, phone };
    const errors = validateErrors(client, checkoutFormValidator);
    if (errors) {
      return;
    }
    const seatIds = seats.map(({ id, discountId }) => {
      return { seatId: id, discountId };
    });
    dispatch(showSpinner());
    SeatsService.checkout(client, seatIds)
      .then((res) => {
        window.location = res.data.message;
      })
      .catch((error) => {
        showError(error.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  return (
    <div className="space-y-3">
      <div className="grid grid-cols-2 gap-x-1">
        <Input
          placeholder="Emri"
          value={name}
          onChange={setName}
          error={getError('name')}
          onFocus={clearErrors}
        />
        <Input
          placeholder="Mbiemri"
          value={surname}
          onChange={setSurname}
          error={getError('surname')}
          onFocus={clearErrors}
        />
      </div>
      <Input
        type="email"
        placeholder="Email"
        value={email}
        onChange={setEmail}
        onFocus={clearErrors}
        error={getError('email')}
      />
      <Input
        className="mb-10"
        placeholder="Numri Telefonit (Opsional)"
        value={phone}
        onChange={setPhone}
        onFocus={clearErrors}
        error={getError('phone')}
      />
      <DefaultButton fullWidth label="Paguaj" onClick={payWithPok} />
    </div>
  );
};

export default CheckoutForm;
