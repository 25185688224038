import React from 'react';
import Moment from 'react-moment';
import { FaCalendarAlt } from 'react-icons/fa';
import { API_URL } from '@constants/index';

const Show = ({ show }) => {
  if (!show) {
    return <div />;
  }
  return (
    <div className="my-5 space-y-1.5 md:space-y-2">
      <p className="text-4xl sm:text-2xl md:text-3xl lg:text-4xl font-medium text-center">
        {show.name}
      </p>
      <div className="flex items-center flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5">
        <img
          src={`${API_URL}/thumbnail/${show.thumbnail}`}
          className="h-auto w-full max-w-md aspect-[0.677] object-cover"
        />
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: show.description }}
            className="text-sm md:text-base"
          />
          <p className="flex items-center space-x-2 text-sm md:text-base">
            <FaCalendarAlt />
            <Moment format="DD.MM.YYYY HH:mm">{show.date}</Moment>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Show;
