import React from 'react';
import Logo from '@assets/images/tob-logo 2.png';
import { Link } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const Navbar = () => {
  const itemsLength = useSelector((state) => _.get(state, 'cartReducer.items', []).length);
  return (
    <nav
      className={`transition duration-300 sticky w-full justify-around z-50 bg-white shadow-md top-0`}>
      <div className="mx-auto px-2 sm:px-3 md:px-7.5 lg:px-15 xl:px-30 block">
        <div className="relative flex items-center justify-between w-full h-18">
          <Link to="/">
            <img src={Logo} className="h-12" alt="tob-logo" />
          </Link>
          <Link to="/cart">
            <div className="relative">
              <FaShoppingCart className="text-3xl" />
              <span className="absolute -top-1 -right-1.5 text-center px-1.5 text-white bg-blue-500 rounded-lg font-semibold text-sm">
                {itemsLength}
              </span>
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
