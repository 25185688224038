import React, { useEffect, useState } from 'react';
import Show from '@components/home/Show';
import Seatmap from '@components/home/seatmap/Seatmap';
import SelectedSeats from '@components/home/selectedseats/SelectedSeats';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import SeatsService from '@services/SeatsService';
import _ from 'lodash';
import { store } from '@redux/Store';
import { updateCart } from '@redux/cart/Action';
import ShowService from '@services/ShowService';
import Legend from '@components/home/Legend';

const Home = () => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(null);
  const [floors, setFloors] = useState([]);
  const history = useHistory();

  useEffect(() => {
    dispatch(showSpinner());
    Promise.all([SeatsService.all(), ShowService.latest()])
      .then((res) => {
        const [seatResponse, showResponse] = res;
        const floors = _.get(seatResponse, 'data.data');
        const show = _.get(showResponse, 'data.data');
        setFloors(floors);
        const state = store.getState();
        const reduxSelectedSeats = _.get(state, 'cartReducer.items', []);
        const filterBookedSeats = (prevSeat) => {
          const floor = floors.find((floor) => prevSeat.floor === parseInt(floor.floor));
          if (!floor) {
            return false;
          }
          const isSeatFree = floor.seats.some((seat) => seat.id === prevSeat.id && !seat.booked);
          return isSeatFree;
        };
        const freeSelectedSeats = reduxSelectedSeats.filter(filterBookedSeats);
        dispatch(updateCart(freeSelectedSeats));
        setSelectedSeats(freeSelectedSeats);
        setFloors(floors);
        setShow(show);
      })
      .catch(() => {
        history.push('/no-show');
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  }, []);

  return (
    <>
      <Show show={show} />
      <h1 className="text-center font-medium text-lg sm:text-xl md:text-2xl lg:text-3xl mb-3">
        Ju lutem zgjidhni rreshtin dhe karrigen
      </h1>
      <Legend />
      <Seatmap selectedSeats={selectedSeats} setSelectedSeats={setSelectedSeats} floors={floors} />
      <SelectedSeats selectedSeats={selectedSeats} />
    </>
  );
};

export default Home;
