import React from 'react';

const CheckoutTable = ({ seats, totalPrice, originalPrice, totalDiscount }) => {
  return (
    <div className="text-sm">
      <div className="text-gray-700 text-xs font-medium uppercase bg-gray-50 grid grid-cols-5">
        <div className="py-3 md:pl-3">Karrigia</div>
        <div className="py-3">Kati</div>
        <div className="py-3">Cmimi</div>
        <div className="py-3">Ulja</div>
        <div className="py-3">Cmimi i ulur</div>
      </div>
      <div className="max-h-150 overflow-y-auto">
        {seats.map((seat, index) => (
          <div className="text-xs font-medium grid grid-cols-5 bg-white border-b" key={index}>
            <div className="py-3 md:pl-3">{seat.row + seat.name}</div>
            <div className="py-3">{seat.floor}</div>
            <div className="py-3">{seat.price + ' ALL'}</div>
            <div className="py-3">{seat.priceDiscount + ' ALL'}</div>
            <div className="py-3">{seat.finalPrice + ' ALL'}</div>
          </div>
        ))}
      </div>
      <div className="text-xs grid grid-cols-3 justify-items-center items-start border-b font-medium md:text-base bg-gray-50">
        <div className="py-3 md:pl-3">
          <p className="text-xs font-mono">Totali para uljes</p>
          {originalPrice + ' ALL'}
        </div>
        <div className="py-3">
          <p className="text-xs font-mono">Ulja Totale</p>
          {totalDiscount + ' ALL'}
        </div>
        <div className="py-3">
          <p className="text-xs font-mono">Totali pas uljes</p>
          {totalPrice + ' ALL'}
        </div>
      </div>
    </div>
  );
};

export default CheckoutTable;
