import React from 'react';

const ShowSeats = ({ floorName, seats }) => {
  const sortAlphabetically = (a, b) => (a.row < b.row ? -1 : 1);
  const sortBySeatNumber = (a, b) => a.name - b.name;

  const splitSeatsByPriceToDifferentArrays = (arrayOfSeats) => {
    const baseArray = [];
    let baseIndex = 0;
    arrayOfSeats.forEach((seat) => (seat.isAdded = false));
    arrayOfSeats.forEach((seat) => {
      const seatsToBePushed = arrayOfSeats.filter(
        ({ price, isAdded }) => price === seat.price && !isAdded
      );
      if (seatsToBePushed.length > 0) {
        baseArray[baseIndex] = { price: seat.price, seats: seatsToBePushed };
        seatsToBePushed.forEach(({ id }) => {
          const seat = arrayOfSeats.find((seat) => seat.id === id);
          if (seat) {
            seat.isAdded = true;
          }
        });
        baseIndex++;
      }
    });
    return baseArray;
  };

  return (
    <div>
      <p className="text-center text-xl font-medium">{floorName}</p>
      <div>
        {splitSeatsByPriceToDifferentArrays(seats).map(({ seats, price }, index) => (
          <div className="grid grid-cols-2 py-2" key={index}>
            <div className="text-gray-600">
              Karrigia{' '}
              {seats
                .sort(sortBySeatNumber)
                .sort(sortAlphabetically)
                .map((seat) => seat.row + seat.name)
                .join(', ')}
            </div>
            <div className="text-right font-semibold">
              <span className="text-gray-600 font-normal">{`${seats.length}X `}</span>
              {price + ' ALL'}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShowSeats;
