import { EMPTY_CART, UPDATE_CART } from '@constants/redux';

const CartReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CART:
      return { ...state, items: payload };
    case EMPTY_CART:
      return {};
    default:
      return state;
  }
};

export default CartReducer;
