import React from 'react';
import CartItem from '@components/cart/CartItem';
import DefaultButton from '@core/buttons/electrons/DefaultButton';

const CartItems = ({ selectedSeats, setSelectedSeats, discounts }) => {
  return (
    <div>
      <div className="max-h-92 lg:max-h-131 overflow-y-auto my-3">
        <table className="table-auto w-full mb-10">
          <thead>
            <tr>
              <td>Karrigia</td>
              <td>Kati</td>
              <td>Ulja</td>
              <td>Cmimi</td>
              <td>Fshi</td>
            </tr>
          </thead>
          <tbody>
            {selectedSeats.map((item, index) => (
              <CartItem
                item={item}
                key={index}
                discounts={discounts}
                setSelectedSeats={setSelectedSeats}
              />
            ))}
          </tbody>
        </table>
      </div>
      <DefaultButton
        sm
        label={
          <div className="flex items-center space-x-1">
            <span>Konfirmo</span>
          </div>
        }
        bgColor="bg-black"
        bgColorHover="hover:opacity-90"
        textColor="text-white"
        link
        to="/checkout"
      />
    </div>
  );
};

export default CartItems;
