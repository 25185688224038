import React from 'react';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const CartButtons = () => {
  return (
    <div className="flex items-center space-x-1.5 my-5">
      <DefaultButton
        sm
        link
        to="/"
        label={
          <div className="flex items-center space-x-1">
            <FaArrowLeft />
            <span>Shko mbrapa</span>
          </div>
        }
      />
    </div>
  );
};

export default CartButtons;
