import { combineReducers } from 'redux';
import spinnerReducer from './spinner/Reducer';
import cartReducer from '@redux/cart/Reducer';

const Reducers = combineReducers({
  spinnerReducer,
  cartReducer
});

export default Reducers;
