import React from 'react';
import Layout from '@hoc/layouts/Layout';
import Home from '@components/home/Home';

const HomePage = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  );
};

export default HomePage;
