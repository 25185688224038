import React from 'react';
import greenSeat from '@assets/svg/green-seat.svg';
import graySeat from '@assets/svg/gray-seat.svg';
import redSeat from '@assets/svg/red-seat.svg';

const Legend = () => {
  const seatClassnames = 'xs:w-2.5 sm:w-3 md:w-3.5 lg:w-4 xl:w-5 2xl:w-6';
  return (
    <div className="flex space-x-5 justify-center">
      <div className="flex items-center space-x-0.5">
        <img src={graySeat} className={seatClassnames} />
        <p className="text-xs sm:text-sm md:text-md">Të lira</p>
      </div>
      <div className="flex items-center space-x-0.5">
        <img src={greenSeat} className={seatClassnames} />
        <p className="text-xs sm:text-sm md:text-md">Të zgjedhura</p>
      </div>
      <div className="flex items-center space-x-0.5">
        <img src={redSeat} className={seatClassnames} />
        <p className="text-xs sm:text-sm md:text-md">Të zëna</p>
      </div>
    </div>
  );
};

export default Legend;
