import React from 'react';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import ShowSeats from '@components/home/selectedseats/ShowSeats';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateCart } from '@redux/cart/Action';

const SelectedSeats = ({ selectedSeats }) => {
  const firstFloorSeats = selectedSeats.filter((seat) => seat.floor === 0);
  const secondFloorSeats = selectedSeats.filter((seat) => seat.floor === 1);
  const thirdFloorSeats = selectedSeats.filter((seat) => seat.floor === 2);

  const history = useHistory();
  const dispatch = useDispatch();

  const updateAndRedirectToCart = () => {
    dispatch(updateCart(selectedSeats));
    history.push('/cart');
  };

  return (
    <div className="md:w-full lg:w-1/2 xl:w-1/3 mx-auto space-y-3 mb-3">
      <h1 className="text-2xl font-semibold text-center self-start">Biletat e zgjedhura</h1>
      {selectedSeats.length > 0 && (
        <div className="space-y-3 relative">
          {firstFloorSeats.length > 0 && <ShowSeats seats={firstFloorSeats} floorName={'Skena'} />}
          {secondFloorSeats.length > 0 && (
            <ShowSeats seats={secondFloorSeats} floorName={'Lozha 1'} />
          )}
          {thirdFloorSeats.length > 0 && (
            <ShowSeats seats={thirdFloorSeats} floorName={'Lozha 2'} />
          )}
          <hr className="border-0.5 border-gray-300 cursor-pointer hover:border-red-500 duration-500" />
          <div className="flex items-center justify-between space-x-5 font-semibold">
            <p className="text-gray-600">Totali i karrigeve</p>
            <p>{selectedSeats.length}</p>
          </div>
          <div className="flex items-center justify-between space-x-5 font-semibold">
            <p className="text-gray-600">Totali</p>
            <p>{selectedSeats.reduce((total, seat) => total + seat.price, 0) + ' ALL'}</p>
          </div>
          <DefaultButton onClick={updateAndRedirectToCart} sm fullWidth label="Shto ne shportë" />
        </div>
      )}
    </div>
  );
};

export default SelectedSeats;
