import React from 'react';
import Navbar from '@hoc/layouts/Navbar';

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
      <div className="px-2 sm:px-3 md:px-4 lg:px-7.5 xl:px-15 2xl:px-30">{children}</div>
    </div>
  );
};

export default Layout;
