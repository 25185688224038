import React from 'react';
import Layout from '@hoc/layouts/Layout';
import ConfirmPurchase from '@components/ConfirmPurchase/ConfirmPurchase';

const ConfirmPurchasePage = () => {
  return (
    <Layout>
      <ConfirmPurchase />
    </Layout>
  );
};

export default ConfirmPurchasePage;
