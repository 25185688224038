import React from 'react';
import 'tippy.js/dist/tippy.css';
import greenSeat from '@assets/svg/green-seat.svg';
import graySeat from '@assets/svg/gray-seat.svg';

const Seat = ({ seats, x, y, selectedSeats }) => {
  const compareSeats = (seat) => seat.x === x && seat.y === y;
  const seat = seats.find(compareSeats);
  const isSeatSelected = seat && selectedSeats.some((selectedSeat) => seat.id === selectedSeat.id);

  const seatWidthClassnames = 'w-2.5 sm:w-4 md:w-4 lg:w-3 xl:w-3.5 2xl:w-4';

  if (seat)
    return (
      <div
        className={`relative flex items-center flex-col cursor-default ${seatWidthClassnames} h-auto rounded`}>
        <img src={isSeatSelected ? greenSeat : graySeat} className={`w-full`} />
        <span className="text-[4px] sm:text-[2px] md:text-[8px] xl:text-[8px] absolute top-0">
          {seat.name}
        </span>
      </div>
    );
  return <div className={seatWidthClassnames} />;
};

export default Seat;
