import React from 'react';
import Layout from '@hoc/layouts/Layout';
import ShoppingCart from '@components/cart/ShoppingCart';

const CartPage = () => {
  return (
    <Layout>
      <ShoppingCart />
    </Layout>
  );
};

export default CartPage;
