import React from 'react';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import HomePage from '@pages/HomePage';
import { Route } from 'react-router';
import CartPage from '@pages/CartPage';
import ConfirmPurchasePage from '@pages/ConfirmPurchasePage';
import CheckoutPage from '@pages/CheckoutPage';
import NoShowPage from '@pages/NoShowPage';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/cart" component={CartPage} />
        <Route exact path="/confirm-purchase" component={ConfirmPurchasePage} />
        <Route exact path="/checkout" component={CheckoutPage} />
        <Route exact path="/no-show" component={NoShowPage} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
};

export default Routes;
