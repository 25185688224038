import React, { useEffect, useState } from 'react';
import CartButtons from '@components/cart/CartButtons';
import CartItems from '@components/cart/CartItems';
import Minimap from '@components/cart/minimap/Minimap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { updateCart } from '@redux/cart/Action';
import DiscountsService from '@services/DiscountsService';
import SeatsService from '@services/SeatsService';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';

const ShoppingCart = () => {
  const cartItems = useSelector((state) => _.get(state, 'cartReducer.items', []));
  const [selectedSeats, setSelectedSeats] = useState([...cartItems]);
  const dispatch = useDispatch();
  const [discounts, setDiscounts] = useState([]);
  const [floors, setFloors] = useState([]);

  useEffect(() => {
    dispatch(showSpinner());
    Promise.all([DiscountsService.all(), SeatsService.all()])
      .then((res) => {
        const [discountResponse, seatsResponse] = res;
        const discounts = _.get(discountResponse, 'data.data', []);
        setDiscounts(discounts);
        const floors = _.get(seatsResponse, 'data.data');
        setFloors(floors);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  }, []);

  useEffect(() => {
    dispatch(updateCart(selectedSeats));
  }, [selectedSeats]);

  return (
    <>
      <h1 className="my-4 text-2xl font-semibold leading-none text-gray-900 md:text-3xl lg:text-4xl">
        Shporta
      </h1>
      <CartButtons />
      {cartItems.length > 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-10 lg:gap-y-0 lg:gap-x-10">
          <CartItems
            discounts={discounts}
            selectedSeats={selectedSeats}
            setSelectedSeats={setSelectedSeats}
          />
          <Minimap floors={floors} selectedSeats={selectedSeats} />
        </div>
      ) : (
        <p>Ju nuk keni asnje bilete ne shporte</p>
      )}
    </>
  );
};

export default ShoppingCart;
