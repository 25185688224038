import React from 'react';
import DeleteButton from '@core/buttons/atoms/DeleteButton';
import { useDispatch } from 'react-redux';
import { updateCart } from '@redux/cart/Action';

const CartItem = ({ item, discounts, setSelectedSeats }) => {
  const dispatch = useDispatch();
  const handleSelectChange = (e) => {
    const discountId = e.target.value;
    setSelectedSeats((prevState) =>
      prevState.map((seat) => {
        if (seat.id === item.id) {
          if (!item.discountId) {
            seat.discountId = discountId;
          } else {
            delete seat.discountId;
          }
        }
        return seat;
      })
    );
  };

  const deleteSeat = () => {
    setSelectedSeats((prevSeats) => {
      const unDeletedSeats = prevSeats.filter((prevSeat) => prevSeat.id !== item.id);
      dispatch(updateCart(unDeletedSeats));
      return unDeletedSeats;
    });
  };

  return (
    <tr className="border-b">
      <td className="py-5 xs:text-xs md:text-sm">{item.row + item.name}</td>
      <td className="py-5 xs:text-xs md:text-sm">{item.floor}</td>
      <td className="py-5">
        <select
          value={item.discountId ?? ''}
          className="bg-gray-50 border border-gray-300 text-gray-900 w-4/6 text-xs md:text-sm rounded-lg block xs:p-1 sm:p-1.5"
          onChange={handleSelectChange}>
          <option value={''}>Pa Ulje</option>
          {discounts.map((discount, index) => (
            <option value={discount.id} key={index}>
              {`${discount.name} ${discount.percentage} %`}
            </option>
          ))}
        </select>
      </td>
      <td className="py-5 xs:text-xs md:text-sm">{item.price} ALL</td>
      <td className="py-5 xs:text-xs md:text-sm">
        <DeleteButton sm onClick={deleteSeat} />
      </td>
    </tr>
  );
};

export default CartItem;
