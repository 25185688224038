import React, { useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import 'tippy.js/dist/tippy.css';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import DeleteButton from '@core/buttons/atoms/DeleteButton';
import greenSeat from '@assets/svg/green-seat.svg';
import graySeat from '@assets/svg/gray-seat.svg';
import redSeat from '@assets/svg/red-seat.svg';
import { useDispatch } from 'react-redux';
import { updateCart } from '@redux/cart/Action';

const Seat = ({ seats, x, y, selectedSeats, setSelectedSeats }) => {
  const compareSeats = (seat) => seat.x === x && seat.y === y;
  const seat = seats.find(compareSeats);
  const isSeatSelected =
    seat && selectedSeats.some((selectedSeat) => seat.id === selectedSeat.id && !seat.booked);
  const [isSeatClicked, setIsSeatClicked] = useState(false);
  const dispatch = useDispatch();

  const deleteSeat = () => {
    setSelectedSeats((prevSeats) => {
      const undDeletedSeats = prevSeats.filter((prevSeat) => prevSeat.id !== seat.id);
      dispatch(updateCart(undDeletedSeats));
      return undDeletedSeats;
    });
  };

  const addSeat = () => {
    setSelectedSeats((prevSeats) => [...prevSeats, seat]);
  };
  const handleSeatClick = (e) => {
    if (seat.booked) {
      return;
    }

    if (e.ctrlKey) {
      if (!isSeatSelected) {
        addSeat();
      } else {
        deleteSeat();
      }
      return;
    }
    setIsSeatClicked(true);
  };
  const handleDeleteButton = () => {
    deleteSeat();
    setIsSeatClicked(false);
  };

  const handleAddButton = () => {
    addSeat();
    setIsSeatClicked(false);
  };

  const seatWidthClassnames = 'w-2.5 sm:w-4 md:w-4 lg:w-5.5 xl:w-6 2xl:w-7';

  if (seat) {
    return (
      <Tippy
        interactive
        visible={isSeatClicked}
        onClickOutside={() => setIsSeatClicked(false)}
        render={() => (
          <div className="px-3 py-2 md:px-5 md:py-3 rounded-lg border bg-white border-2">
            <h1 className="text-center text-base md:text-lg font-semibold mb-1">
              {seat.row + seat.name}
            </h1>
            <h1 className="text-center text-sm md:text-base mb-2">{seat.price} ALL</h1>
            {isSeatSelected ? (
              <DeleteButton fullWidth xs label="Fshi" onClick={handleDeleteButton} />
            ) : (
              <DefaultButton fullWidth xs label="Zgjidh" onClick={handleAddButton} />
            )}
          </div>
        )}>
        <div
          className={`relative flex items-center flex-col ${
            seat.booked ? 'cursor-default' : 'cursor-pointer'
          } ${seatWidthClassnames} h-auto ${
            isSeatClicked && 'border border-black md:border-2'
          } rounded`}
          onClick={handleSeatClick}>
          <img
            src={seat.booked ? redSeat : isSeatSelected ? greenSeat : graySeat}
            className={`w-full ${isSeatClicked && 'drop-shadow-2xl'}`}
          />
          <span className="text-[4px] sm:text-[6px] md:text-[8px] lg:text-[10px] xl:text-xs absolute top-0">
            {seat.name}
          </span>
        </div>
      </Tippy>
    );
  }
  return <div className={`${seatWidthClassnames} h-auto`} />;
};

export default Seat;
