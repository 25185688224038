import React from 'react';
import Lottie from '@core/Lottie';

const NoShowAnimation = () => {
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 lg:space-y-10">
      <div className="w-36 h-52 md:w-80 md:h-100 lg:w-60 lg:h-80 mx-auto overflow-hidden mb-10">
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            reverse: true
          }}
          animationData={require('@assets/animations/curtains.json')}
        />
      </div>
      <p className="text-lg lg:text-2xl font-medium text-center">
        Momentalisht nuk ka asnjë shfaqe. Kthehuni më vonë
      </p>
    </div>
  );
};

export default NoShowAnimation;
