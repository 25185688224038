import React, { useState } from 'react';
import Floor from '@components/home/seatmap/Floor';

const Seatmap = ({ selectedSeats, setSelectedSeats, floors }) => {
  const [floorIndex, setFloorIndex] = useState(0);

  const Floors = floors.map((floor, index) => (
    <Floor
      key={index}
      floor={floor}
      selectedSeats={selectedSeats}
      setSelectedSeats={setSelectedSeats}
    />
  ));

  return (
    <div className="my-3">
      <select
        className="mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-xs md:text-sm rounded-lg block mx-auto py-1.5 px-20 lg:py-2"
        onChange={(e) => setFloorIndex(parseInt(e.target.value))}
        defaultValue={0}>
        <option value={0}>Skena</option>
        <option value={1}>Lozha 1</option>
        <option value={2}>Lozha 2</option>
      </select>
      {Floors[floorIndex]}
      {floorIndex === 0 && (
        <div className="w-11/12 md:w-5/6 lg:w-11/12 xl:w-4/6 mx-auto text-center text-xs sm:text-sm md:text-base lg:text-lg border-x border-t border-black border-b-0 pt-1 sm:pt-2 md:pt-3">
          Skena
        </div>
      )}
    </div>
  );
};

export default Seatmap;
