const checkoutFormValidator = {
  name: {
    presence: {
      allowEmpty: false,
      message: 'Emri nuk mund te jete bosh'
    }
  },
  surname: {
    presence: {
      allowEmpty: false,
      message: 'Mbiemri nuk mund te jete bosh'
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'Emaili nuk mund te jete bosh'
    },
    email: {
      message: 'Emaili nuk eshte i sakte'
    }
  },
  phone: {
    presence: {
      allowEmpty: true
    }
  }
};

export default checkoutFormValidator;
