import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import * as Loading from '@assets/animations/loading.json';
import * as Success from '@assets/animations/success.json';
import * as Failure from '@assets/animations/failure.json';
import TicketService from '@services/TIcketService';
import { useDispatch } from 'react-redux';
import { clearCart } from '@redux/cart/Action';

const ConfirmPurchase = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('orderId');
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const animationData = loading ? Loading : success ? Success : Failure;

  useEffect(() => {
    setTimeout(() => {
      TicketService.confirmPurchase(orderId)
        .then(() => {
          setSuccess(true);
          dispatch(clearCart());
        })
        .catch((err) => {
          setError(err.response.data.message);
        })
        .finally(() => setLoading(false));
    }, 3000);
  }, [orderId]);

  return (
    <div className="flex flex-col items-center h-screen justify-center space-y-3">
      <div className="w-30 md:w-40 sm:w-50">
        <Lottie
          options={{
            loop: loading,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
        />
      </div>
      {loading ? (
        ''
      ) : (
        <p className="text-2xl text-center mt-15">
          {success ? 'Biletat tuaja u prenotuan me sukses' : error}
        </p>
      )}
    </div>
  );
};

export default ConfirmPurchase;
