import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import SeatsService from '@services/SeatsService';
import CheckoutTable from '@components/checkout/CheckoutTable';
import CheckoutForm from '@components/checkout/CheckoutForm';
import { showError } from '@utils/helpers';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

const Checkout = () => {
  const cartItems = useSelector((state) => _.get(state, 'cartReducer.items', []));
  const [seats, setSeats] = useState([]);
  const dispatch = useDispatch();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const history = useHistory();

  useEffect(() => {
    dispatch(showSpinner());
    const payload = cartItems.map(({ id, discountId }) => {
      return { seatId: id, discountId };
    });
    SeatsService.calculatePrice(payload)
      .then((res) => {
        const data = _.get(res, 'data.data', {});
        const totalPrice = _.get(data, 'totalPrice', 0);
        const seats = _.get(data, 'seatPrices', []);
        const originalPrice = _.get(data, 'originalPrice', 0);
        const totalDiscount = _.get(data, 'totalDiscount', 0);
        setTotalDiscount(totalDiscount);
        setOriginalPrice(originalPrice);
        setTotalPrice(totalPrice);
        setSeats(seats);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  }, []);

  return (
    <>
      <h1 className="my-4 text-2xl font-semibold leading-none text-gray-900 md:text-3xl lg:text-4xl">
        Checkout
      </h1>
      <div className="my-5">
        <DefaultButton
          sm
          label={
            <div className="flex items-center space-x-1">
              <FaArrowLeft />
              <span>Shko mbrapa</span>
            </div>
          }
          onClick={() => history.push('/cart')}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-10 lg:gap-y-0 lg:gap-x-10">
        <CheckoutTable
          seats={seats}
          totalPrice={totalPrice}
          originalPrice={originalPrice}
          totalDiscount={totalDiscount}
        />
        <CheckoutForm seats={seats} />
      </div>
    </>
  );
};

export default Checkout;
